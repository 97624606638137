.filters {
  position: -webkit-sticky; /* Safari */
  position: sticky;

  top: 0;
  padding: 1rem;
  border-bottom: 1px solid rgb(217, 217, 217);
  background-color: white;
  h1 {
    margin: 0.5rem 0;
  }
}
