.posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
  padding: 0 0 1rem;
  flex-direction: column;

  &.gallery {
    flex-direction: row;
  }
}
