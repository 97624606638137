@import 'constants.scss';

.post {
  padding: 0.5rem;
  margin: 0.5rem;
  border-bottom: 1px solid rgb(217, 217, 217);
  display: flex;

  img {
    border-radius: 5px;
    box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.75);
    align-self: center;
    width: 100px;
    height: 66px;
  }

  .track-info {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 1rem;

    .artist {
      margin: 0;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .track {
      margin: 0;
      font-size: 1rem;
    }

    .artist,
    .track {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100vw - 160px);
    }
    .genres {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 0.5rem;
      .ant-tag {
        font-size: 0.6rem;
        margin-left: 0.5rem;
      }
      width: 140px;
    }
  }

  &.active {
    background-color: lighten(#001529, 5%);
    border: 1px solid lighten(#394b59, 8%);
    border-radius: 10px;
    color: white;
  }

  &.gallery {
    flex-direction: column;
    border: none;
    img {
      width: 140px;
      height: 105px;
    }
    .track-info {
      display: flex;
      flex-direction: column;
      margin: 0;

      .artist {
        margin: 0;
        font-size: 0.9rem;
        font-weight: bold;
      }

      .track {
        margin: 0;
        font-size: 0.8rem;
      }
    }

    .track-info {
      flex-direction: column;
      .artist,
      .track {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 140px;
      }
    }
  }

  @media screen and (max-width: $lg) {
    padding: 0.1rem;
    margin: 0.2rem;
  }
}
