.player {
  padding: 1rem 0;
  background-color: lighten(#001529, 5%);
  margin-bottom: 1rem;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .metadata {
    h3,
    h4 {
      color: white;
    }
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    text-align: end;
    //padding-right: 1rem;
  }

  .video {
    margin: 0 auto;
  }

  .controls {
    display: flex;
    justify-content: center;
    background-color: lighten(#394b59, 10%);
    border: 1px solid darken(#394b59, 10%);
    padding: 0.5rem;
    border-radius: 10px;
    max-width: 160px;
    margin-top: 1rem;
    button {
      margin: 0 0.5rem;
    }
  }
}
