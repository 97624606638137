.app {
  overflow: auto;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  .sider {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: white;

      svg {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
  .logo {
    color: white;
    text-align: center;
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  .body {
    padding: 1rem;
    .acct-actions {
      display: flex;
      height: 80px;
      justify-content: flex-end;
    }
    .main-content {
      background: #ffffff;
      overflow-y: scroll;
      width: 100%;
      padding: 0 0 1rem;
    }
  }
}
